import React, { type ComponentClass, Suspense, lazy } from 'react';

import { GrafanaInternalApiClient } from '@grafana-incident-app/api/GrafanaInternalApiClient';
import type { GrafanaIncidentSettings } from '@grafana-incident-app/types';
import { setClient } from '@grafana-irm/core/client';
import {
  addExtensionLink,
  exposeComponent,
  getIsIrmPluginPresent,
  PluginId,
  registerPluginServiceWorker,
} from '@grafana-irm/core/plugin';
import { queryClient } from '@grafana-irm/core/queryClient';
import { AppPlugin, type AppPluginMeta, type AppRootProps, type PluginConfigPageProps } from '@grafana/data';
import { PluginPage } from '@grafana/runtime';
import { Spinner } from '@grafana/ui';

import { QueryClientProvider } from '@tanstack/react-query';

setClient(new GrafanaInternalApiClient());

const MinimalAppContextProvider = lazy(async () => ({
  default: (await import('@plugins/grafana-incident-app')).MinimalAppContextProvider,
}));
const App = lazy(() => import('@grafana-incident-app/pages/App'));
const AppConfig = lazy(() => import('@grafana-incident-app/pages/AppConfig'));
const ServiceCenterIncidentTable = lazy(
  () => import('@grafana-incident-app/components/ServiceCenter/ServiceCenterIncidentTable')
);
const LazyIncidentError = lazy(() => import('@grafana-irm/components').then((mod) => ({ default: mod.IncidentError })));

void registerPluginServiceWorker(PluginId.Incident, process.env.CURRENT_VERSION);

function AppWithLoader(props: AppRootProps<GrafanaIncidentSettings>): React.ReactElement {
  return (
    <Suspense fallback={<PluginPage children={<Spinner />} />}>
      <QueryClientProvider client={queryClient}>
        <MinimalAppContextProvider>
          <App {...props} />
        </MinimalAppContextProvider>
      </QueryClientProvider>
    </Suspense>
  );
}

// NOTE: Used by grafana code, so not an used export
// ts-prune-ignore-next
const plugin = new AppPlugin<GrafanaIncidentSettings>()
  .setRootPage(AppWithLoader as unknown as ComponentClass<AppRootProps<GrafanaIncidentSettings>, unknown>)
  .addConfigPage({
    title: 'Configuration',
    icon: 'cog',
    body: AppConfig as unknown as ComponentClass<
      PluginConfigPageProps<AppPluginMeta<GrafanaIncidentSettings>>,
      unknown
    >,
    id: 'incident-configuration',
  });

async function getDeclareIncidentOnClick(shouldAttachURL = false) {
  const mod = await import('./utils/getDeclareIncidentClickHandler');
  return mod.getDeclareIncidentClickHandler({ shouldAttachURL });
}

if (!getIsIrmPluginPresent()) {
  addExtensionLink<GrafanaIncidentSettings>(plugin, {
    title: 'Declare incident',
    description: 'Declare an incident and attach the panel context to it',
    category: 'Incident',
    icon: 'fire',
    targets: ['grafana/dashboard/panel/menu'],
    onClick: async (...args) => {
      const onClickHandler = await getDeclareIncidentOnClick(true);
      await onClickHandler(...args);
    },
  });

  addExtensionLink<GrafanaIncidentSettings>(plugin, {
    title: 'Declare an incident', // titles of each extension need to be unique
    description: 'Declare an incident',
    targets: ['grafana/explore/toolbar/action'],
    category: 'Incident',
    icon: 'fire',
    onClick: async (...args) => {
      const onClickHandler = await getDeclareIncidentOnClick();
      await onClickHandler(...args);
    },
  });
  exposeComponent(plugin, {
    id: 'grafana-incident-app/service-center-table-component/v1',
    title: 'Service center table component',
    description: 'Service center table component',
    component: function IncidentComponent() {
      return (
        <Suspense>
          <MinimalAppContextProvider>
            <QueryClientProvider client={queryClient}>
              <LazyIncidentError>
                <ServiceCenterIncidentTable></ServiceCenterIncidentTable>
              </LazyIncidentError>
            </QueryClientProvider>
          </MinimalAppContextProvider>
        </Suspense>
      );
    },
  });
}

export { plugin };
