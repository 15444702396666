import type { AppPlugin, KeyValue } from '@grafana/data';
import type { GrafanaBootConfig } from '@grafana/runtime';

export const PluginId = {
  OnCall: 'grafana-oncall-app',
  Irm: 'grafana-irm-app',
  Incident: 'grafana-incident-app',
} as const;
export type PluginId = (typeof PluginId)[keyof typeof PluginId];

export function getIsIrmPluginPresent(): boolean {
  return PluginId.Irm in (window?.grafanaBootData?.settings as GrafanaBootConfig).apps;
}

export function isCurrentPlugin(pluginId: PluginId): boolean {
  return getPluginId(null as unknown as PluginId) === pluginId;
}

export function getPluginId(defaultValue: PluginId = PluginId.Irm): PluginId {
  try {
    return (process.env.PLUGIN_ID ?? defaultValue) as PluginId;
  } catch (error) {
    return defaultValue;
  }
}

export function addExtensionComponent<T extends KeyValue = KeyValue>(
  plugin: AppPlugin<T>,
  config: Parameters<typeof plugin.addComponent>[0]
): void {
  if (plugin.addComponent != null) {
    // v11
    plugin.addComponent(config);
  } else {
    // v10
    const { targets, ...reusedConfig } = config;
    const extensionPointId = typeof targets === 'string' ? targets : targets[0];
    if (extensionPointId != null) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      plugin.configureExtensionComponent({
        ...reusedConfig,
        extensionPointId,
      });
    }
  }
}

export function addExtensionLink<T extends KeyValue = KeyValue, C extends object = object>(
  plugin: AppPlugin<T>,
  config: Parameters<typeof plugin.addLink<C>>[0]
): void {
  if (plugin.addLink != null) {
    // v11
    plugin.addLink<C>(config);
  } else {
    // v10
    const { targets, ...reusedConfig } = config;
    const extensionPointId = typeof targets === 'string' ? targets : targets[0];
    if (extensionPointId != null) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      plugin.configureExtensionLink<C>({
        ...reusedConfig,
        extensionPointId,
      });
    }
  }
}

export function exposeComponent<T extends KeyValue = KeyValue, P = null>(
  plugin: AppPlugin<T>,
  config: Parameters<typeof plugin.exposeComponent<P>>[0]
): void {
  if (plugin.exposeComponent != null) {
    // v11
    plugin.exposeComponent(config);
  } // there's no v10 equivalent
}

export async function registerPluginServiceWorker(pluginId: PluginId, version = 'unknown'): Promise<void> {
  if ('serviceWorker' in navigator) {
    /**
     * Service Workers: An Introduction and Purpose in This Project
     *
     * A service worker is a script run by the browser in the background, separate from the web page,
     * enabling features like asset caching, offline access, push notifications, and background syncing.
     *
     * In this project, we are registering a service worker to achieve the following goals:
     *
     * 1. Old version access:
     *    Service workers allow us to cache assets and data, enabling the application to work when the
     *    server no longer has our application.
     *
     * 2. **Performance Optimization**:
     *    By caching essential assets, we can significantly improve the loading time of our application,
     *    making it more responsive and faster to load on repeat visits.
     *
     * NOTE: Service workers require a secure origin (HTTPS) and are not fully supported in older browsers.
     * NOTE: We use workbox-webpack-plugin to generate our service-worker.js which supports dev mode and many other great features!
     */
    navigator.serviceWorker
      .register(`/public/plugins/${pluginId}/service-worker.js?v=${version}`)
      .then((registration) => {
        console.info(
          `ServiceWorker registration for plugin ${pluginId} (version ${version}) successful with scope:`,
          registration.scope
        );
      })
      .catch((error) => {
        console.error(`ServiceWorker registration for plugin ${pluginId} (version ${version}) failed:`, error);
      });
  } else {
    console.error('ServiceWorker registration failed, no navigator.serviceWorker');
  }
}
